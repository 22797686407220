import React from 'react'
import { Link } from 'gatsby'
import { Cart, useCartItemList } from '@foundations-of-grace-pub/checkout'
import BodyButton from '../../../components/BodyButton'

const CheckoutButton = () => {
  const { getSubtotal } = useCartItemList()
  const checkoutEnabled = getSubtotal() > 0

  return (
    <BodyButton
      as={Link}
      to={checkoutEnabled ? '/checkout' : null}
      disabled={!checkoutEnabled}
      onClick={e => {
        if (!checkoutEnabled) e.preventDefault()
      }}
    >
      Checkout
    </BodyButton>
  )
}

const CartPageBody = () => (
  <>
    <h1>Shopping cart</h1>
    <Cart />
    <br />
    <CheckoutButton />
  </>
)

export default CartPageBody

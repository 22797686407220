import React from 'react'
import SEO from '../../../components/Seo'
import CartPageBody from './CartPageBody'
import BaseLayout from '../../../components/layouts/BaseLayout'
import Container from '../../../components/layouts/Container'

const GatsbyCartPage = () => (
  <BaseLayout>
    <SEO title="Cart" />
    <Container>
      <CartPageBody />
    </Container>
  </BaseLayout>
)

export default GatsbyCartPage
